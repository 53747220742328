<!-- System: STA
    Purpose: This compoment will display team members
            table in grid view
-->
<template>
  <v-row class="px-sm-16 mt-10">
    <v-col
      cols="12"
      sm="6"
      md="3"
      lg="3"
      xl="2"
      v-for="member in filtered"
      :key="member.id"
      :class="member.display == false ? 'dom-render' : ''"
    >
      <!-- Team Member Card -->
      <v-card
        class="mx-auto mt-5 team-mem-box"
        max-width="260"
        style="min-height: 100% !important"
        elevation="10"
      >
        <v-row>
          <v-col cols="6" class="d-flex">
            <!-- Tooltip For Team Member Details -->
            <v-tooltip top color="transparent">
              <template v-slot:activator="{ on, attrs }">
                <span class="ml-16">
                  <!-- Team Member Image -->
                  <img
                    v-if="member.image == null"
                    src="../../assets/images/no-member.svg"
                    @error="
                      $event.target.src = require('../../assets/images/no-member.svg')
                    "
                    class="img-fluid proj-img mt-5"
                    height="70"
                    v-bind="attrs"
                    v-on="on"
                  />
                  <img
                    v-else
                    :src="`${url}storage/${member.image}`"
                    @error="
                      $event.target.src = require('../../assets/images/no-member.svg')
                    "
                    height="70"
                    alt="Thumbnail"
                    class="img-fluid proj-img mt-5"
                    style="
                      border-radius: 100%;
                      max-width: 65px;
                      max-height: 65px;
                      border: none;
                    "
                    v-bind="attrs"
                    v-on="on"
                  />
                </span>
              </template>
              <span>
                <UserInfo :member="userInfo(member.id)"> </UserInfo>
              </span>
            </v-tooltip>
            <!-- Tooltip For Team Member Status -->
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-badge
                    :color="
                      memberStatus(member) === 'online'
                        ? '#1ce21c'
                        : memberStatus(member) === 'idle'
                        ? '#f7b12e'
                        : member.is_active &&
                          (member.today_minutes === '0' ||
                            !member.today_minutes)
                        ? 'red'
                        : member.is_active && member.today_minutes
                        ? '#17a2b8'
                        : ''
                    "
                    dot
                    class="badge-status mt-4"
                  >
                  </v-badge>
                </div>
              </template>
              <span>
                {{
                  memberStatus(member) === "online"
                    ? "Online"
                    : memberStatus(member) === "idle"
                    ? "Idle"
                    : member.is_active &&
                      (member.today_minutes === "0" || !member.today_minutes)
                    ? "Absent"
                    : member.is_active && member.today_minutes
                    ? "Worked"
                    : ""
                }}
              </span>
            </v-tooltip>
          </v-col>
          <v-col cols="6" class="">
            <!-- Edit User Info Button -->
            <v-btn
              text
              x-small
              class="ml-4 mr-n3"
              @click="showUserInfoModal(member)"
              v-if="selectedCompanyUserStatus !== 'employee'"
            >
              <v-icon class="icon-color" data-trigger="edit_profile_member">
                mdi-pencil
              </v-icon>
            </v-btn>
            <!-- Team Member Action Buttons -->
            <v-menu offset-y :close-on-click="true">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  x-small
                  v-bind="attrs"
                  v-on="on"
                  @click="socketMember = member"
                >
                  <v-icon class="roles-dropdown-icon icon-color" elevation="0">
                    mdi-dots-vertical
                  </v-icon>
                </v-btn>
              </template>
              <!-- Submenu List dropdown -->
              <v-list class="text-left">
                <!-- Assign Project Button -->
                <v-list-item
                  dense
                  v-show="!member.silent_user"
                  v-if="selectedCompanyUserStatus !== 'employee'"
                >
                  <v-list-item-title>
                    <v-btn
                      class="small-drop-down"
                      small
                      color="#2758f6"
                      outlined
                      style="background: white"
                      @click="assignProjects(member)"
                      >Assign Projects
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
                <!-- Assign Team Member Button -->
                <v-list-item
                  dense
                  v-show="!member.silent_user"
                  v-if="selectedCompanyUserStatus !== 'employee'"
                >
                  <v-list-item-title
                    ><v-btn
                      small
                      class="small-drop-down"
                      color="#2758f6"
                      @click="assignTeamMembers(member)"
                      outlined
                      style="background: white"
                      >Assign Team Members</v-btn
                    ></v-list-item-title
                  >
                </v-list-item>
                <!-- Instant Capture Button -->
                <v-list-item dense>
                  <v-list-item-title
                    ><v-btn
                      small
                      color="#2758f6"
                      :disabled="!checkSocket"
                      outlined
                      style="background: white"
                      class="small-drop-down"
                      @click="instant_capture_req(member)"
                      >Instant Capture
                    </v-btn></v-list-item-title
                  >
                </v-list-item>
                <!-- Real Time Monitoring Button -->
                <v-list-item
                  dense
                  v-if="
                    selectedCompany.is_real_time_allowed &&
                    member.super_secure_user !== 'SUPERSECUREUSER'
                  "
                >
                  <v-list-item-title
                    ><v-btn
                      small
                      color="#2758f6"
                      :disabled="!checkSocket"
                      outlined
                      style="background: white"
                      class="small-drop-down"
                      @click="real_time_req(member)"
                      >Real Time Monitoring
                    </v-btn></v-list-item-title
                  >
                </v-list-item>
                <!-- Face Registration Button -->
                <v-list-item
                  dense
                  v-if="
                    !member.silent_user &&
                    member.member_status === 'active' &&
                    selectedCompanyUserStatus !== 'employee' &&
                    FaceRegistrationAllowed
                  "
                >
                  <v-list-item-title
                    ><v-btn
                      small
                      color="#2758f6"
                      outlined
                      style="background: white"
                      class="small-drop-down"
                      @click="faceRegistrationTeamMember(member)"
                      >Face Registration
                    </v-btn></v-list-item-title
                  >
                </v-list-item>
                <!-- Go To Reports Button -->
                <v-list-item
                  dense
                  v-if="selectedCompanyUserStatus !== 'employee'"
                >
                  <v-list-item-title
                    ><v-btn
                      small
                      color="#2758f6"
                      outlined
                      style="background: white"
                      class="small-drop-down"
                      @click="go_to_report(member)"
                      >Go to Reports
                    </v-btn></v-list-item-title
                  >
                </v-list-item>
                <!-- Archive User Button -->
                <v-list-item
                  dense
                  v-if="
                    member.member_status === 'active' &&
                    selectedCompanyUserStatus !== 'employee'
                  "
                >
                  <v-list-item-title
                    ><v-btn
                      small
                      color="#2758f6"
                      outlined
                      style="background: white"
                      class="small-drop-down"
                      @click="
                        (archiveMemberModal = true), (memberToArchive = member)
                      "
                      ><v-icon>mdi-archive</v-icon>Archive User
                    </v-btn></v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <!-- Member Details -->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <p
              v-if="member.name.length <= 15"
              class="d-flex justify-center user-name"
            >
              {{ member.name }}
            </p>
            <p
              v-bind="attrs"
              v-on="on"
              class="d-flex justify-center user-name"
              v-else
            >
              {{ member.name.slice(0, 15) + ".." }}
            </p>
          </template>
          <span>{{ member.name }}</span>
        </v-tooltip>

        <span class="small-text" v-if="activeProject(member) != ''">
          Working on :
          <span>{{ activeProject(member) }}</span>
        </span>
        <v-card-text>
          <v-chip-group
            class="text-center"
            active-class="deep-purple accent-4 white--text"
            column
            v-if="member.is_active !== null"
          >
            <v-chip
              class="ml-5 rounded-0"
              style="
                height: 32px;
                width: 236px;
                border-radius: 2px;
                background-color: #fbf3e7;
              "
              >Today's Hours : {{ convert_mins_to_hour(member.today_minutes) }}
            </v-chip>
            <v-chip
              class="ml-5 rounded-0"
              style="
                height: 32px;
                width: 236px;
                border-radius: 2px;
                background-color: #fcf1f0;
              "
              >Weekly Hours :
              {{ convert_mins_to_hour(member.weekly_minutes) }}</v-chip
            >
            <v-chip
              class="ml-5 rounded-0"
              style="
                height: 32px;
                width: 236px;
                border-radius: 2px;
                background-color: #e7f4f2;
              "
              >Monthly Hours :
              {{ convert_mins_to_hour(member.monthly_minutes) }}</v-chip
            >
          </v-chip-group>
          <v-chip-group
            class="text-center mt-12 ml-4"
            active-class="deep-purple accent-4 white--text"
            column
            v-else
          >
            <v-chip
              class="ml-5 rounded-0"
              style="background-color: rgba(232, 152, 2, 0.12)"
              >Status : Pending
            </v-chip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  style="margin-left: 70px !important"
                  v-bind="attrs"
                  v-on="on"
                  @click="reInviteMember(member)"
                >
                  <v-icon>mdi-restore</v-icon>
                </v-btn>
              </template>
              <span>Member Re-Invited</span>
            </v-tooltip>
          </v-chip-group>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- Archive Member Dialog Box / Modal-->
    <v-dialog
      v-model="archiveMemberModal"
      width="500"
      transition="dialog-top-transition"
    >
      <v-card>
        <v-card-title class="headline red lighten-2">
          Are you sure?
        </v-card-title>
        <v-card-text class="mt-4">
          <h4>
            If you archive this team member, he will temporarily be removed from
            all the projects and status will be changed to inactive.
          </h4>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            text
            @click="(archiveMemberModal = false), (memberToArchive = '')"
          >
            Cancel
          </v-btn>
          <v-btn color="warning" outlined text @click="move_to_archive()">
            Archive
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { min_to_hour } from "@/assets/js/utils";
import { baseURL } from "@/assets/js/urls";
import UserInfo from "../includes/UserInfo";
import { mapState } from "vuex";
export default {
  components: {
    UserInfo,
  },
  name: "TeamMembers",
  props: ["members"],
  data() {
    return {
      archiveMemberModal: false,
      memberToArchive: "",
      socketMember: "",
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    filtered() {
      return this.members;
    },
    /**
     * Return if member is
     * currently online
     */
    checkSocket() {
      if (this.socketMember && this.socket_employee)
        return !!this.socket_employee.find(
          (user) => user.user_id === this.socketMember.id
        );
    },
    /**
     * Return base url of api
     */
    url() {
      return baseURL.API_URL;
    },
    /**
     * Return all socket employee from store
     */
    socket_employee() {
      return this.$store.state.custom.all_store_socket_employee;
    },
    /**
     * Return the selected company
     */
    selectedCompany() {
      return this.$store.state.custom.selected_company;
    },
    selectedCompanyUserStatus() {
      const company = this.$store.state.custom.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.user_status;
      else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
    FaceRegistrationAllowed() {
      const company = this.$store.state.custom.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.facial_attendance_allowed;
      else {
        return false;
      }
    },
  },
  sockets: {
    real_time_monitoring(image, user_id) {
      this.$root.$emit("real_time_monitor", image, user_id);
    },
  },
  filters: {
    activeProjectName(name) {
      return name.length > 15
        ? name
            .slice(0, 6)
            .concat("...")
            .concat(name.slice(name.length - 6))
        : name;
    },
  },
  methods: {
    /**
     * This function is resposible for
     * pass the member data to edit
     * user info modal component
     */
    showUserInfoModal(member) {
      this.$eventBus.$emit("editUserInfo", member);
    },
    /**
     * This function is resposible for
     * pass the data to register team
     * member face in modal component
     */
    faceRegistrationTeamMember(member) {
      this.$eventBus.$emit("faceRegistration", member);
    },
    /**
     * This function is resposible for
     * pass the data to assign team
     * member modal component
     */
    assignTeamMembers(member) {
      this.$eventBus.$emit("assignTeamMembers", member);
    },
    /**
     * This function is resposible for
     * pass the data to assign project
     * modal component
     */
    assignProjects(member) {
      this.$eventBus.$emit("assignProjects", member);
    },
    /**
     * This function is resposible for
     * move the member to reports page
     */
    go_to_report(member) {
      this.$store.commit("custom/set_report_member_id", member.id);
      let routeData = this.$router.resolve({
        name: "Reports",
        params: { id: this.$route.params.id },
      });
      window.open(routeData.href, "_blank");
    },
    /**
     * This function is resposible for
     * move to member in archived list
     * api call
     */
    move_to_archive() {
      if (this.memberToArchive.member_status === "archive") {
        return;
      }
      let data = {
        member_id: this.memberToArchive.id,
        company_id: this.$route.params.id,
      };
      this.$store.commit("custom/set_loader", true);
      this.$store
        .dispatch("custom/archive_member", data)
        .then((response) => {
          this.$store.dispatch("custom/get_archived_team_members_list", data);
          this.$store.commit("custom/toggle_loader", false);
          this.archiveMemberModal = false;
          if (response.data.error === false) {
            this.$store.commit("custom/set_loader", false);
            this.$store.dispatch("custom/get_active_team_members_list", {
              company_id: this.$route.params.id,
            });
            this.$socket.emit(
              "archive_member",
              this.member.id + "_" + this.$route.params.id
            );
            this.$root.$emit("snack_bar", {
              show: true,
              message: "You have successfully archived a member.",
              snackbarColor: "green",
            });
            this.memberToArchive = "";
          }
        })
        .catch((error) => {
          this.$root.$emit("snack_bar", {
              show: true,
              message: error.response.data.error_msg,
              snackbarColor: "red",
            });
          this.$store.commit("custom/toggle_loader", false);
        });
    },
    /**
     * This function is resposible for
     * instant capture.
     * pass data to instant capture modal
     */
    instant_capture_req(member) {
      this.$store.commit("custom/toggle_loader", true);
      let member_id = member.id;
      let user_id = this.user.id;
      let auth_token = this.user.auth_token;
      this.$socket.emit(
        "instant capture",
        {
          user_id,
          member_id,
          company_id: this.$route.params.id,
          auth_token,
        },
        (response, user_id) => {
          this.$root.$emit("send_member_id", member_id);
          this.$root.$emit("instant_capture", response, user_id);
        }
      );
    },
    /**
     * This function is resposible for
     * real time monitoring.
     * pass data to eal time monitoring modal.
     */
    real_time_req(member) {
      let member_id = member.id;
      let company_id = this.$route.params.id;
      let user_id = this.user.id;
      let memberid = member_id;
      let auth_token = this.user.auth_token;
      this.$root.$emit("real_send_member_id", member_id);
      this.$socket.emit("real_time_monitoring", {
        user_id,
        memberid,
        company_id,
        auth_token,
      });
    },
    /**
     * This function is resposible for
     * returning member status
     * e.g online , idle etc
     */
    memberStatus(memberCheck) {
      if (!memberCheck || !this.socket_employee) return "";
      let member = this.socket_employee.find(
        (user) => user.user_id === memberCheck.id
      );
      if (!member) return "";

      let pro = member.hasOwnProperty("task_running");
      return pro ? (member.task_running ? "online" : "idle") : "online";
    },
    /**
     * This function is resposible for
     * returning member data
     */
    userInfo(id) {
      let member = this.$store.state.custom.get_team_members_list.find(
        (data) => data.id == id
      );
      if (member) {
        return member;
      } else {
        return [];
      }
    },
    /**
     * This function is resposible for
     * converting mins to hours
     */
    convert_mins_to_hour(minutes) {
      let time = min_to_hour(minutes);
      let hour = time.hrs < 10 ? "0" + time.hrs : time.hrs;
      let mins = time.mins < 10 ? "0" + time.mins : time.mins;
      return hour + ":" + mins;
    },
    /**
     * This function is resposible for
     * returning member active project
     */
    activeProject(members) {
      if (!members || !this.socket_employee) {
        return "";
      }
      let member = this.socket_employee.find(
        (user) => user.user_id === members.id
      );
      member = member && member.project ? member.project : "";
      return member;
    },
    reInviteMember(member) {
      let data = {
        company_id: this.$route.params.id,
        email: member.name,
      };

      this.$store
        .dispatch("custom/re_invite_member", data)
        .then((response) => {
          this.$store.commit("custom/toggle_progress_loader", false);
          if (response.data.error === false) {
            this.$root.$emit("snack_bar", {
              show: true,
              message: response.data.error_msg,
              snackbarColor: "green",
            });
          } else {
            this.$root.$emit("snack_bar", {
              show: true,
              message: response.data.error_msg,
              snackbarColor: "red",
            });
          }
        })
        .catch(() => {
          this.$store.commit("custom/toggle_progress_loader", false);
        });
    },
  },
};
</script>
<style scoped>
.dom-render {
  display: none;
}
.user-name {
  color: #36454f;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 27px;
  text-align: center;
}

.team-mem-box {
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 10px 30px -20px #242e4c;
}

@media (max-width: 2100px) and (min-width: 1400px) {
  .img-fluid {
    margin-left: 30px;
  }
}

.badge-status {
  position: relative;
  top: 55px;
  left: -9px;
}
</style>
